.wave-box {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  // &::after {
  //   content: '';
  //   display: block;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: linear-gradient(to bottom, rgba(#e8a, 1), rgba(#def, 0) 80%, rgba(white, 0.5));
  //   z-index: 11;
  //   transform: translate3d(0, 0, 0);
  // }
}

.wave {
  opacity: 0.4;
  transition: top 1s ease-out;
  position: absolute;
  top: -100%;
  left: -50%;
  background: #000;
  width: 200%;
  height: 200%;
  transform-origin: 50% 48%;
  border-radius: 43%;
  animation: drift 3000ms infinite linear;
}

.wave.-three {
  animation: drift 5000ms infinite linear;
}

.wave.-two {
  animation: drift 7000ms infinite linear;
  opacity: 0.1;
  background: yellow;
}

@keyframes drift {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}
