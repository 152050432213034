html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button,
input,
label {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  vertical-align: baseline;
  text-decoration: none;
  font: 100% 'Apple SD Gothic Neo', AppleSDGothicNeo-Light, Helvetica, 'Malgun Gothic', Arial,
    sans-serif;
  letter-spacing: -0.5px;
}
u {
  text-decoration: underline;
}
ul,
li {
  list-style-type: none;
}
img {
  display: block;
}
input,
select {
  appearance: none;
  outline: none;
}
button {
  color: #000;
  background-color: transparent;
}
em {
  font-weight: bold;
}
